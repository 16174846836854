import './App.css';

function App() {
  return (
    <div className="App">
      <div className='aplication-header-detail'></div> 
      <div className='aplication-header'>
         Área do Ciente
      </div>
      <div className='aplication-container'> 
      <div className='aplication'>
        <a href='https://centralize-consultoria.web.app/' target="_blank" className='aplication-logo centralizeconsultoria'></a>
        <div className='aplication-detail'></div>
      </div>
      <div className='aplication'>
      <a href='https://centralizeweb.com.br/' target="_blank" className='aplication-logo centralizeweb'></a>
      <div className='aplication-detail'></div>
      </div>
      <div className='aplication'>
        <a href='https://onvio.com.br/clientcenter/pt/auth?r=%2Fhome' target="_blank" className='aplication-logo onvio'></a>
        <div className='aplication-detail'></div>
      </div>
      
      </div>
      
      <div className='container-post-excicms'>
        <div className='post-excicms'>
      </div>

      </div>
    </div>
  );
}

export default App;
